<template>
  <tr class="creator-step-item" :passed="passed">
    <td bold>{{ label }}</td>
    <td>
      <span v-if="!value" warn>{{ warn }}</span>
      <span v-else>{{ value }}</span>
    </td>
    <td><iconic :name="`${passed ? 'mf_check' : 'mf_times'}`" /></td>
  </tr>
</template>

<script>
export default {
  props: ["label", "value", "passed", "warn"],
};
</script>

<style lang="scss">
.creator-step-item {
  [warn] {
    background-color: rgba($color: $primary_color, $alpha: 0.8);
    color: #fff;
    padding: $mpadding/4 $mpadding/2;
    border-radius: $mpadding/4;
    font-size: 90%;
  }
}
</style>
