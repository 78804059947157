<template>
  <div class="creator-step">
    <div class="creator-step-title">{{ $locale.words["complete_profile"] }}</div>
    <div class="creator-step-content">
      <table width="100%">
        <CreatorBecomeStepsItem :label="$locale.words['user_name']" :value="$user.user" :passed="!!$user.user" :warn="$locale['is_required']" />
        <CreatorBecomeStepsItem :label="$locale.words['full_name']" :value="$user.name" :passed="!!$user.name" :warn="$locale['is_required']" />
        <CreatorBecomeStepsItem :label="$locale.words['location']" :value="$user.country" :passed="!!$user.country" :warn="$locale['is_required']" />
        <CreatorBecomeStepsItem :label="$locale.words['phone']" :value="$user.phone" :passed="!!$user.phone" :warn="$locale['phone_is_required']" />
        <CreatorBecomeStepsItem
          :label="$locale.words['date_of_birth']"
          :value="date_locale($user.birthday)"
          :passed="!!$user.birthday"
          :warn="$locale['is_required']"
        />
        <CreatorBecomeStepsItem label="Instagram" :value="$user.instagram" :passed="!!$user.instagram" :warn="$locale['instagram_is_required']" />
      </table>
    </div>

    <Spacer num="" />

    <div class="creatorStepData" v-if="isCompleteProfile">
      <CreatorBecomeData />
    </div>

    <Spacer num="" />

    <div class="creator-step-options">
      <iconic v-if="loading" strong dcolor name="gspinner" />
      <GradientButton v-else-if="canContinue" :label="$locale['continue']" :to="`/user/creator?apply=step2`" icon="arrowRight" dir="rtl" />
      <GradientButton
        v-else-if="!isCompleteProfile"
        :label="$locale['complete_profile']"
        :to="`${$links['my-profile']}&redirect=/user/creator?apply=step1`"
      />
      <GradientButton v-else-if="!isDataComplete" :label="$locale['complete_data']" @click="saveCreatorApplyData" />
    </div>
  </div>
</template>

<script>
import CreatorBecomeData from "./CreatorBecomeData.vue";
import CreatorBecomeStepsItem from "./CreatorBecomeStepsItem.vue";
export default {
  mixins: [CreatorBecomeData],
  components: {
    CreatorBecomeStepsItem,
    CreatorBecomeData,
  },
  computed: {
    canContinue: function() {
      return this.isCompleteProfile && this.isDataComplete;
    },
    isCompleteProfile: function() {
      return this.$user.user && this.$user.name && this.$user.country && this.$user.birthday && this.$user.phone && this.$user.instagram;
    },
  },
  mounted: function() {
    if (this.isCompleteProfile) {
      this.$store.commit("setApplyStep", { step: "step1", value: true });
    }
  },
};
</script>
