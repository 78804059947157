<template>
  <div class="CreatorBecomeData">
    <form id="ApplyCreatorData" enctype="multipart/form-data" @change="dataChanged" v-on:submit.prevent>
      <div class="CreatorBecomeDataInput ToggleInput experienceAsCreator">
        <div>{{ $locale["experienceAsCreator"] }}</div>
        <Spacer num="0.3" />
        <div>
          <ToggleValues
            @input="(i) => inputSet('experienceAsCreator', i.value)"
            name="experienceAsCreator"
            :value="experienceAsCreator"
            :values="[
              { value: 'no', label: $locale['no'] },
              { value: 'yes', label: $locale['yes'] },
            ]"
          />
        </div>
      </div>
      <Spacer num="2" />
      <div class="CreatorBecomeDataInput ToggleInput areYouCurrentlyWorking">
        <div>{{ $locale["areYouCurrentlyWorking"] }}</div>
        <Spacer num="0.3" />
        <div>
          <ToggleValues
            @input="(i) => inputSet('areYouCurrentlyWorking', i.value)"
            name="areYouCurrentlyWorking"
            :value="areYouCurrentlyWorking"
            :values="[
              { value: 'no', label: $locale['no'] },
              { value: 'yes', label: $locale['yes'] },
            ]"
          />
        </div>
      </div>
      <div class="CreatorBecomeDataInput ToggleInput" v-if="areYouCurrentlyWorking === 'yes'">
        <div class="block-input currentPlatformName">
          <InputLabel :label="$locale['name_of_platform']" :required="areYouCurrentlyWorking === 'yes'" />
          <input type="text" name="currentPlatformName" :placeholder="$locale['name_of_platform']" v-model="currentPlatformName" />
        </div>
      </div>
      <Spacer num="1" />
      <div class="CreatorBecomeDataInput howKnowUs">
        <div class="block-input">
          <InputLabel :label="$locale['about_us']" required="true" />
          <input type="text" name="howKnowUs" :placeholder="$locale['how_u_know_us']" v-model="howKnowUs" />
        </div>
      </div>
      <div class="CreatorBecomeDataInput applyComments">
        <div class="block-input">
          <InputLabel :label="$locale['comments']" />
          <TextareaRich name="applyComments" :placeholder="$locale['want_add_commenst']" :content="applyComments" />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  data: function() {
    return {
      loading: false,
      host: process.env.VUE_APP_API_HOST,
      formId: "ApplyCreatorData",
      endpoint: "user/ApplyCreatorData",
      axios: {},
      inputClassError: "inputFieldError",
      areYouCurrentlyWorking: "no",
      experienceAsCreator: "no",
      howKnowUs: "",
      applyComments: "",
      currentPlatformName: "",
    };
  },
  methods: {
    inputSet: function(name, value) {
      this[name] = value;
      this.dataChanged();
    },
    saveCreatorApplyData: async function() {
      let form = document.getElementById(this.formId);
      let formData = new FormData(form);
      this.loading = true;
      Go.removeAllClassOnElement(form, this.inputClassError);

      try {
        this.req = await this.$http.post(this.host + this.endpoint, formData, this.axios);
      } catch (error) {
        this.req = { data: { error, message: Go.getErrorMessage(error) } };
      }

      this.saveDataResponse(this.req);
      this.loading = false;
    },
    saveDataResponse: function({ data: { success, message, title, field } = {} } = {}) {
      if (!success) {
        this.$store.state.verifications.DataApplyCreatorComplete = false;
        this.showAlert({ message: message, type: "error", title: title || "Error", icon: "ui_error" });

        if (field) {
          Go.addClass(`#${this.formId} .${field}`, this.inputClassError);
        }

        return;
      }

      this.$store.dispatch("getProfile");
      this.$store.state.verifications.DataApplyCreatorComplete = true;
      this.$router.push("/user/creator?apply=step2");
    },
    dataChanged: function() {
      this.error = null;
      this.$store.state.verifications.DataApplyCreatorComplete = false;
    },
  },
  computed: {
    dataProfile: function() {
      return this.$profile && this.$profile.creatorApply;
    },
    isDataComplete: function() {
      return this.$store.state.verifications.DataApplyCreatorComplete;
    },
  },
  beforeMount: function() {
    this.areYouCurrentlyWorking = this.dataProfile && this.dataProfile.areYouCurrentlyWorking;
    this.experienceAsCreator = this.dataProfile && this.dataProfile.experienceAsCreator;
    this.howKnowUs = this.dataProfile && this.dataProfile.howKnowUs;
    this.applyComments = this.dataProfile && this.dataProfile.applyComments;
    this.currentPlatformName = this.dataProfile && this.dataProfile.currentPlatformName;
  },
};
</script>
